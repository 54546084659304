import { useStaticQuery, graphql } from "gatsby"

const useOfficesQuery = () => {
  const { allWpOffice } = useStaticQuery(graphql`
    query OfficeQuery {
      allWpOffice(
        sort: {title: ASC}
      )
      {
        nodes {
          title
          uri
          acf {
            applyNowUrl
            bookInspectionUrl
            officeImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1600
                  )
                }
              }
            }
            googleMap {
              latitude
              longitude
            }
          }
          officeDetails {
            id
            email
            name
            phone
            address {
              postcode
              state
              streetAddress
              suburb
            }
          }
        }
      }
    }
  `)
  return allWpOffice.nodes || []
}

export default useOfficesQuery