const handleDarkmode = (e) => {
  var darkModeOn = e.matches;
  var largeFavicons = document.querySelectorAll('link[rel="icon"]');
  if (!largeFavicons) {
    return;
  }
  if (darkModeOn) {
    largeFavicons.forEach(ele => {
      ele.href = require("./src/assets/images/favicon-light.svg").default
    })
  } else {
    largeFavicons.forEach(ele => {
      ele.href = require("./src/assets/images/favicon-dark.svg").default
    })
  }
}

exports.onRouteUpdate = () => {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  darkModeMediaQuery.addListener(e => {
    handleDarkmode(darkModeMediaQuery)
  })
  handleDarkmode(darkModeMediaQuery)
}