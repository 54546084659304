import React, { useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import { Close, RightArrow } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
function getCookie(name) {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return false
}

const Popup = ({ title, content, thanksContent, subscribeTitle, image }) => {
  const [popup, setPopup] = useState(false)
  const [subscribe, setSubscribe] = useState(false)
  const [thank, setThank] = useState(false)
  const [initial, setInitial] = useState(true)
  const firstNameInput = useRef();
  const lastNameInput = useRef();
  const emailInput = useRef();

  React.useEffect(() => {
    if (getCookie('newsletter-popup')) return

    setTimeout(() => {
      setPopup(true)
      setCookie('newsletter-popup', true, 365)
    }, 6000)
  }, [])

  const openSubscribeHandle = () => {
    setInitial(false)
    setSubscribe(prev => !prev)
  }

  const submitHandle = (e) => {
    e.preventDefault()

    // https://zapier.com/editor/196953872/published/196953873/
    fetch(`https://hooks.zapier.com/hooks/catch/2181815/3hd5knr/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        firstName: firstNameInput.current.value,
        lastName: lastNameInput.current.value,
        email: emailInput.current.value,
      })
    })
    .then(() => {
      setSubscribe(false)
      setThank(prev => !prev)
    })

  }

  const closePopupHandle = () => {
    setPopup(false)
  }

  const closeAndNavigate = () => {
    setPopup(false)
    navigate('/appraisals/')
  }

  return (
    <div className={ `popup ${ popup ? 'active' : '' }` }>
      <div className='popup__close-bg' onClick={ closePopupHandle }></div>
      <div className='popup__wrapper'>
        <button className='popup__close' onClick={ closePopupHandle }>
          <Close color='#0F2130'/>
        </button>
        {
          initial &&
          <div className='popup__content'>
            { title && <h2>{ title }</h2> }
            { content && <p>{ parse(content) }</p> }

            <div className='popup__buttons'>
              <button className='btn btn-blue-steel' onClick={ closeAndNavigate }>Request an Appraisal</button>
              <button className='btn btn-blue-steel' onClick={ openSubscribeHandle }>Subscribe <span>to CH</span></button>
            </div>
          </div>
        }

        {
          subscribe &&
          <div className='popup__subscribe'>
            { subscribeTitle && <h2>{ subscribeTitle }</h2> }
            <form onSubmit={ submitHandle }>
              <div className='form__row form__row--double'>
                <input
                  type='text'
                  name='first_name'
                  required
                  placeholder='First Name'
                  ref={firstNameInput}
                />
                <input
                  type='text'
                  name='last_name'
                  required
                  placeholder='Last Name'
                  ref={lastNameInput}
                />
              </div>
              <div className='form__row'>
                <input
                  type='email'
                  name='email'
                  placeholder='Email address'
                  required
                  ref={emailInput}
                />
                <button type='submit'><RightArrow color='#F7F5D6'/></button>
              </div>
            </form>
          </div>
        }

        {
          thank &&
          <div className='popup__thank'>
            <h2>You’re all set! <br/> Thanks for joining us.</h2>
            { thanksContent && <p>{ thanksContent }</p> }
          </div>
        }

        { thank && <button className='close' onClick={ closePopupHandle }>Close</button> }

        <div className='popup__image'>
          { image && <GatsbyImage image={ image.localFile.childImageSharp.gatsbyImageData } alt={ title }/> }
        </div>
      </div>
    </div>
  )
}

export default Popup