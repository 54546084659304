import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"
import officesQuery from "../hooks/use-offices-query"

import '../assets/scss/main.scss'

import Sidebar from '../components/sidebar'
import Header from '../components/header'
import Footer from '../components/footer'
import Popup from '../components/popup'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()
  const offices = officesQuery()
  
  const { popup } = data.wp.siteSettings.acf

  React.useEffect(() => {
    document.documentElement.style.setProperty(`--textColor`, pageContext?.darkMode ? '#EDEDBD' : '#0F2130')
    document.documentElement.style.setProperty(`--bgColor`, pageContext?.darkMode ? '#0F2130' : '#EDEDBD')
    document.documentElement.style.setProperty(`--bgColorSundae`, pageContext?.darkMode ? '#152E42' : '#F7F5D6')
    document.documentElement.style.setProperty(`--borderColor`, pageContext?.darkMode ? '#16334B' : '#D6D690')
    document.documentElement.style.setProperty(`--btnBorderPowderColor`, pageContext?.darkMode ? '#EDEDBD' : '#3F5B73')
    document.documentElement.style.setProperty(`--btnPowderColor`, pageContext?.darkMode ? '#EDEDBD' : '#0F2130')
    document.documentElement.style.setProperty(`--white`, pageContext?.darkMode ? '#000000' : '#FFFFFF')
    document.documentElement.style.setProperty(`--bgColorMarina`, pageContext?.darkMode ? '#EDEDBD' : '#0D283E')
    document.documentElement.style.setProperty(`--bgColorPowder`, pageContext?.darkMode ? '#0D283E' : '#3F5B73')
    document.documentElement.style.setProperty(`--rgbaBgColor`, pageContext?.darkMode ? 'rgb(15 33 48 / 90%)' : 'rgb(237 237 189 / 90%)')
  }, [pageContext])

  return (
    <div className='site-wrapper'>
      <div className='site-wrapper__sidebar'>
        <Sidebar />
      </div>
      <div className='site-wrapper__main'>
        <Header location={location} data={data} offices={offices} pageContext={pageContext} />
          <main>
            { children }
          </main>
        <Footer data={data} offices={offices} />
        <Popup {...popup} />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
