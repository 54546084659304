import React from 'react'

const Logo = ({ color }) => (
  <svg width='216' height='27' viewBox='0 0 216 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2278 21.448C15.0718 21.448 18.8798 18.2 19.7758 13.356H15.4918C14.7918 15.82 12.8038 17.388 10.2278 17.388C6.8958 17.388 4.4038 14.756 4.4038 11.228C4.4038 7.7 6.8958 5.04 10.2278 5.04C12.6918 5.04 14.6518 6.496 15.4638 8.792H19.6918C18.7958 4.144 14.9878 0.979998 10.2278 0.979998C4.4598 0.979998 0.0917969 5.404 0.0917969 11.228C0.0917969 17.024 4.4598 21.448 10.2278 21.448Z'
      fill={ color }/>
    <path
      d='M28.9294 21.448C33.6054 21.448 37.1054 18.004 37.1054 13.44C37.1054 8.876 33.6054 5.46 28.9294 5.46C24.2534 5.46 20.7534 8.876 20.7534 13.44C20.7534 18.004 24.2534 21.448 28.9294 21.448ZM28.9294 17.584C26.5774 17.584 24.8974 15.904 24.8974 13.44C24.8974 11.004 26.5774 9.296 28.9294 9.296C31.2814 9.296 32.9614 11.004 32.9614 13.44C32.9614 15.904 31.2814 17.584 28.9294 17.584Z'
      fill={ color }/>
    <path
      d='M47.4863 5.46C45.9183 5.46 44.4903 5.908 43.3423 6.72V0.447998H39.2543V21.028H42.2503L42.7543 19.74C44.0143 20.832 45.6383 21.448 47.4863 21.448C52.0783 21.448 55.4383 18.032 55.4383 13.468C55.4383 8.876 52.0783 5.46 47.4863 5.46ZM47.1503 17.584C44.7703 17.584 43.0343 15.792 43.0343 13.44C43.0343 11.088 44.7703 9.296 47.1503 9.296C49.5303 9.296 51.2663 11.088 51.2663 13.44C51.2663 15.792 49.5303 17.584 47.1503 17.584Z'
      fill={ color }/>
    <path
      d='M68.6556 0.447998V6.72C67.4796 5.908 66.0796 5.46 64.5116 5.46C59.9196 5.46 56.5596 8.876 56.5596 13.468C56.5596 18.032 59.9196 21.476 64.5116 21.476C66.3596 21.476 67.9836 20.832 69.2436 19.768L69.7196 21.028H72.7436V0.447998H68.6556ZM64.8476 17.612C62.4676 17.612 60.7316 15.82 60.7316 13.468C60.7316 11.088 62.4676 9.324 64.8476 9.324C67.2276 9.324 68.9636 11.088 68.9636 13.468C68.9636 15.82 67.2276 17.612 64.8476 17.612Z'
      fill={ color }/>
    <path
      d='M90.5529 13.412C90.5529 9.044 87.3609 5.46 82.7129 5.46C78.2329 5.46 74.8729 8.904 74.8729 13.44C74.8729 17.976 78.2609 21.448 82.7129 21.448C86.2409 21.448 89.1249 19.348 90.2169 16.044H85.9329C85.3169 17.136 84.1409 17.808 82.7129 17.808C80.7249 17.808 79.3809 16.66 79.0169 14.588H90.4689C90.5249 14.196 90.5529 13.804 90.5529 13.412ZM82.7129 8.96C84.5609 8.96 85.8769 9.968 86.4649 11.816H79.1289C79.6049 10.024 80.8929 8.96 82.7129 8.96Z'
      fill={ color }/>
    <path
      d='M101.259 5.544C99.2149 5.544 97.3949 6.412 96.2469 7.756L95.4629 5.88H92.6629V21H96.7509V13.076C96.7509 10.64 98.0389 9.128 100.139 9.128C101.903 9.128 102.771 10.22 102.771 12.516V21H106.859V11.844C106.859 8.008 104.703 5.544 101.259 5.544Z'
      fill={ color }/>
    <path d='M121.454 1.4V8.96H114.062V1.4H109.834V21H114.062V12.908H121.454V21H125.682V1.4H121.454Z' fill={ color }/>
    <path
      d='M141.199 5.88L140.779 7.308C139.463 6.16 137.755 5.46 135.795 5.46C131.259 5.46 127.871 8.876 127.871 13.44C127.871 18.004 131.259 21.448 135.795 21.448C137.727 21.448 139.407 20.776 140.695 19.628L141.059 21H144.251V5.88H141.199ZM136.131 17.584C133.751 17.584 132.015 15.792 132.015 13.44C132.015 11.06 133.751 9.296 136.131 9.296C138.511 9.296 140.247 11.06 140.247 13.44C140.247 15.82 138.511 17.584 136.131 17.584Z'
      fill={ color }/>
    <path
      d='M157.855 5.88L154.075 15.204L150.043 5.88H145.591L152.003 19.852L151.947 19.964C151.023 22.26 150.519 22.792 148.531 22.792H147.131V26.46H148.811C152.199 26.46 153.795 24.92 155.615 20.804L162.195 5.88H157.855Z'
      fill={ color }/>
    <path
      d='M168.311 21.448C172.147 21.448 174.667 19.432 174.667 16.352C174.667 12.656 171.503 12.152 169.039 11.704C167.471 11.424 166.183 11.172 166.183 10.164C166.183 9.296 166.939 8.736 168.199 8.736C169.627 8.736 170.467 9.324 170.495 10.528H174.387C174.359 7.504 171.951 5.46 168.311 5.46C164.671 5.46 162.235 7.448 162.235 10.304C162.235 13.86 165.315 14.504 167.723 14.924C169.319 15.176 170.635 15.484 170.635 16.52C170.635 17.528 169.599 18.06 168.479 18.06C167.023 18.06 165.987 17.388 165.959 16.044H161.983C161.983 19.264 164.531 21.448 168.311 21.448Z'
      fill={ color }/>
    <path
      d='M184.042 21.448C188.718 21.448 192.218 18.004 192.218 13.44C192.218 8.876 188.718 5.46 184.042 5.46C179.366 5.46 175.866 8.876 175.866 13.44C175.866 18.004 179.366 21.448 184.042 21.448ZM184.042 17.584C181.69 17.584 180.01 15.904 180.01 13.44C180.01 11.004 181.69 9.296 184.042 9.296C186.394 9.296 188.074 11.004 188.074 13.44C188.074 15.904 186.394 17.584 184.042 17.584Z'
      fill={ color }/>
    <path
      d='M202.963 5.544C200.919 5.544 199.099 6.412 197.951 7.756L197.167 5.88H194.367V21H198.455V13.076C198.455 10.64 199.743 9.128 201.843 9.128C203.607 9.128 204.475 10.22 204.475 12.516V21H208.563V11.844C208.563 8.008 206.407 5.544 202.963 5.544Z'
      fill={ color }/>
    <path d='M211.196 21H215.536V16.632H211.196V21Z' fill={ color }/>
  </svg>
)

const StackedLogo = ({ color }) => (
  <svg width='107' height='51' viewBox='0 0 107 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.136 21C14.98 21 18.788 17.752 19.684 12.908H15.4C14.7 15.372 12.712 16.94 10.136 16.94C6.804 16.94 4.312 14.308 4.312 10.78C4.312 7.252 6.804 4.592 10.136 4.592C12.6 4.592 14.56 6.048 15.372 8.344H19.6C18.704 3.696 14.896 0.532 10.136 0.532C4.368 0.532 0 4.956 0 10.78C0 16.576 4.368 21 10.136 21Z'
      fill={ color }/>
    <path
      d='M28.8376 21C33.5136 21 37.0136 17.556 37.0136 12.992C37.0136 8.428 33.5136 5.012 28.8376 5.012C24.1616 5.012 20.6616 8.428 20.6616 12.992C20.6616 17.556 24.1616 21 28.8376 21ZM28.8376 17.136C26.4856 17.136 24.8056 15.456 24.8056 12.992C24.8056 10.556 26.4856 8.848 28.8376 8.848C31.1896 8.848 32.8696 10.556 32.8696 12.992C32.8696 15.456 31.1896 17.136 28.8376 17.136Z'
      fill={ color }/>
    <path
      d='M47.3945 5.012C45.8265 5.012 44.3985 5.46 43.2505 6.272V0H39.1625V20.58H42.1585L42.6625 19.292C43.9225 20.384 45.5465 21 47.3945 21C51.9865 21 55.3465 17.584 55.3465 13.02C55.3465 8.428 51.9865 5.012 47.3945 5.012ZM47.0585 17.136C44.6785 17.136 42.9425 15.344 42.9425 12.992C42.9425 10.64 44.6785 8.848 47.0585 8.848C49.4385 8.848 51.1745 10.64 51.1745 12.992C51.1745 15.344 49.4385 17.136 47.0585 17.136Z'
      fill={ color }/>
    <path
      d='M68.5638 0V6.272C67.3878 5.46 65.9878 5.012 64.4198 5.012C59.8278 5.012 56.4678 8.428 56.4678 13.02C56.4678 17.584 59.8278 21.028 64.4198 21.028C66.2678 21.028 67.8918 20.384 69.1518 19.32L69.6278 20.58H72.6518V0H68.5638ZM64.7558 17.164C62.3758 17.164 60.6398 15.372 60.6398 13.02C60.6398 10.64 62.3758 8.876 64.7558 8.876C67.1358 8.876 68.8718 10.64 68.8718 13.02C68.8718 15.372 67.1358 17.164 64.7558 17.164Z'
      fill={ color }/>
    <path
      d='M90.4611 12.964C90.4611 8.596 87.2691 5.012 82.6211 5.012C78.1411 5.012 74.7811 8.456 74.7811 12.992C74.7811 17.528 78.1691 21 82.6211 21C86.1491 21 89.0331 18.9 90.1251 15.596H85.8411C85.2251 16.688 84.0491 17.36 82.6211 17.36C80.6331 17.36 79.2891 16.212 78.9251 14.14H90.3771C90.4331 13.748 90.4611 13.356 90.4611 12.964ZM82.6211 8.512C84.4691 8.512 85.7851 9.52 86.3731 11.368H79.0371C79.5131 9.576 80.8011 8.512 82.6211 8.512Z'
      fill={ color }/>
    <path
      d='M101.167 5.096C99.1231 5.096 97.3031 5.964 96.1551 7.308L95.3711 5.432H92.5711V20.552H96.6591V12.628C96.6591 10.192 97.9471 8.68 100.047 8.68C101.811 8.68 102.679 9.772 102.679 12.068V20.552H106.767V11.396C106.767 7.56 104.611 5.096 101.167 5.096Z'
      fill={ color }/>
    <path d='M12.768 24.952V32.512H5.376V24.952H1.148V44.552H5.376V36.46H12.768V44.552H16.996V24.952H12.768Z' fill={ color }/>
    <path
      d='M32.513 29.432L32.093 30.86C30.777 29.712 29.069 29.012 27.109 29.012C22.573 29.012 19.185 32.428 19.185 36.992C19.185 41.556 22.573 45 27.109 45C29.041 45 30.721 44.328 32.009 43.18L32.373 44.552H35.565V29.432H32.513ZM27.445 41.136C25.065 41.136 23.329 39.344 23.329 36.992C23.329 34.612 25.065 32.848 27.445 32.848C29.825 32.848 31.561 34.612 31.561 36.992C31.561 39.372 29.825 41.136 27.445 41.136Z'
      fill={ color }/>
    <path
      d='M49.169 29.432L45.389 38.756L41.357 29.432H36.905L43.317 43.404L43.261 43.516C42.337 45.812 41.833 46.344 39.845 46.344H38.445V50.012H40.125C43.513 50.012 45.109 48.472 46.929 44.356L53.509 29.432H49.169Z'
      fill={ color }/>
    <path
      d='M59.6246 45C63.4606 45 65.9806 42.984 65.9806 39.904C65.9806 36.208 62.8166 35.704 60.3526 35.256C58.7846 34.976 57.4966 34.724 57.4966 33.716C57.4966 32.848 58.2526 32.288 59.5126 32.288C60.9406 32.288 61.7806 32.876 61.8086 34.08H65.7006C65.6726 31.056 63.2646 29.012 59.6246 29.012C55.9846 29.012 53.5486 31 53.5486 33.856C53.5486 37.412 56.6286 38.056 59.0366 38.476C60.6326 38.728 61.9486 39.036 61.9486 40.072C61.9486 41.08 60.9126 41.612 59.7926 41.612C58.3366 41.612 57.3006 40.94 57.2726 39.596H53.2966C53.2966 42.816 55.8446 45 59.6246 45Z'
      fill={ color }/>
    <path
      d='M75.3555 45C80.0315 45 83.5315 41.556 83.5315 36.992C83.5315 32.428 80.0315 29.012 75.3555 29.012C70.6795 29.012 67.1795 32.428 67.1795 36.992C67.1795 41.556 70.6795 45 75.3555 45ZM75.3555 41.136C73.0035 41.136 71.3235 39.456 71.3235 36.992C71.3235 34.556 73.0035 32.848 75.3555 32.848C77.7075 32.848 79.3875 34.556 79.3875 36.992C79.3875 39.456 77.7075 41.136 75.3555 41.136Z'
      fill={ color }/>
    <path
      d='M94.2765 29.096C92.2325 29.096 90.4125 29.964 89.2645 31.308L88.4805 29.432H85.6805V44.552H89.7685V36.628C89.7685 34.192 91.0565 32.68 93.1565 32.68C94.9205 32.68 95.7885 33.772 95.7885 36.068V44.552H99.8765V35.396C99.8765 31.56 97.7205 29.096 94.2765 29.096Z'
      fill={ color }/>
    <path d='M102.51 44.552H106.85V40.184H102.51V44.552Z' fill={ color }/>
  </svg>
)

const DropdownArrow = ({ color }) => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg' className="svg-arrow-down">
    <path fillRule='evenodd' clipRule='evenodd'
          d='M9.28965 1.28554C9.40341 1.17125 9.40341 0.98695 9.28965 0.872654L8.87677 0.460312C8.76302 0.346563 8.57817 0.346563 8.46442 0.460312L4.99998 3.92531L1.53554 0.460312C1.42125 0.346563 1.23695 0.346563 1.12265 0.460312L0.710313 0.872654C0.596562 0.986951 0.596562 1.17125 0.710313 1.28554L4.79383 5.3685C4.90758 5.4828 5.09243 5.4828 5.20618 5.3685L9.28965 1.28554Z'
          fill={ color }/>
  </svg>
)

const DownCurledArrow = ({ color }) => (
  <svg width='103' height='209' viewBox='0 0 103 209' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.26835 173.885C23.3053 185.302 39.2997 196.796 55.2181 208.367C57.6723 210.157 61.505 207.129 60.5096 204.325C57.3141 195.338 51.4971 188.384 46.0713 180.668C39.9993 172.042 35.9381 162.636 33.6452 152.331C32.655 147.374 24.8598 149.361 26.0174 154.308C27.4424 160.349 29.4494 166.226 32.0973 171.803C31.4848 171.793 30.8419 172.06 30.567 172.635C30.2922 173.209 30.1679 173.714 30.144 174.213C28.4071 171.715 26.9932 168.996 25.6613 166.299C20.4787 155.912 16.6355 144.872 13.9807 133.58C8.68077 111.042 8.17958 87.1204 12.917 64.4373C17.3794 43.1089 27.5563 13.2472 52.4527 9.15053C63.0526 7.39805 73.3434 11.4739 82.1996 16.9674C86.4391 19.5976 90.4123 22.607 94.154 25.9059C96.6173 28.0713 98.6436 30.3502 102.086 29.5586C102.438 29.477 102.762 29.1683 102.835 28.8143C104.433 22.3975 93.7323 16.0801 89.3896 12.8509C82.2826 7.59539 74.1284 3.37782 65.3749 1.72454C45.2557 -2.08418 28.3402 9.50177 18.151 26.1643C-3.80201 62.045 -3.65199 112.045 9.90005 150.737C12.6437 158.581 15.8005 166.81 20.1315 174.102C17.8529 172.436 15.5817 170.784 13.3031 169.119C13.3533 168.275 13.1116 167.496 12.2573 166.707C10.5282 165.123 7.97568 165.505 6.73526 167.469C6.45239 167.909 6.16951 168.35 5.89415 168.803C4.73868 170.614 5.7144 172.755 7.28134 173.878L7.26835 173.885ZM42.5817 190.083C42.7428 190.302 42.9038 190.521 43.0649 190.74C42.8053 190.56 42.5588 190.373 42.2992 190.194C42.3922 190.175 42.4832 190.122 42.5817 190.083Z'
      fill={ color }/>
  </svg>
)

const DownCurledLongArrow = ({ color }) => (
  <svg width='111' height='257' viewBox='0 0 111 257' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.06949 255.195C20.2784 244.604 31.186 237.61 45.2141 231.121C52.0079 227.98 58.3897 224.029 65.1834 220.887C72.4727 217.524 79.9081 215.397 86.8227 211.19C88.2979 210.289 87.1127 207.991 85.5495 208.798C72.1764 215.83 56.5264 220.08 42.5819 226.172C40.1414 227.245 37.6668 228.244 35.1922 229.243C59.2525 206.368 74.7455 172.486 87.1296 143.445C96.8167 120.687 104.424 97.1465 108.073 72.6237C109.971 59.9747 111.587 47.1003 110.517 34.3311C109.583 23.1603 105.978 12.2581 104.025 1.23965C103.774 -0.144645 101.73 0.377295 101.909 1.72331C103.377 12.6012 105.973 23.2936 107.023 34.2139C108.195 46.5693 105.428 59.7916 103.148 71.8499C99.1413 93.0102 92.0857 113.704 83.9654 133.606C75.6899 153.866 65.0926 173.571 54.0119 192.429C44.437 208.666 32.2701 223.651 16.6484 233.936C21.1371 222.611 26.5281 211.42 31.3821 200.631C35.5382 191.453 42.1837 178.195 38.559 167.981C37.3508 164.576 32.4704 164.782 32.162 168.633C30.1359 194.09 15.3623 216.014 6.5734 239.685C5.19541 240.353 3.78225 240.983 2.35251 241.559C-1.53425 243.152 0.209245 248.13 3.52029 248.949C3.29802 249.758 3.03956 250.566 2.8173 251.375C1.88179 254.973 6.259 257.815 9.0519 255.176L9.06949 255.195Z'
      fill={ color }/>
  </svg>
)

const LeftCurledArrow = ({ color }) => (
  <svg width='169' height='61' viewBox='0 0 169 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M168.122 58.1639C159.866 44.4677 150.657 32.5662 136.751 24.1479C123.264 15.9697 107.379 11.1593 91.6279 10.1142C63.8317 8.29172 34.0709 19.381 15.1479 40.1C15.6896 38.1564 16.2186 36.2347 16.7916 34.3383C19.0035 26.9411 19.9152 15.3868 25.5993 9.74762C30.3419 5.04677 22.9633 -2.24665 18.23 2.48879C12.3186 8.3928 11.369 17.2317 9.51645 24.9962C7.22884 34.5459 4.03798 44.69 3.2529 54.5602C2.47369 55.2696 1.75707 56.0735 1.18653 57.0051C0.539442 58.0684 1.22913 59.1815 2.25282 59.5932C8.38684 62.1028 15.5919 57.6877 21.4379 55.4353C28.5338 52.6996 35.9438 49.9539 42.386 45.8915C46.1172 43.5383 43.7472 36.5715 39.2346 38.374C32.2268 41.1602 25.6305 44.8559 18.6911 47.8278C38.6617 30.4621 62.8749 19.1906 90.2434 20.4603C117.896 21.7465 148.675 37.6213 165.081 59.9615C166.396 61.7411 169.345 60.1538 168.144 58.1765L168.122 58.1639Z'
      fill={ color }/>
  </svg>
)

const RightCurledArrow = ({ color }) => (
  <svg width='136' height='49' viewBox='0 0 136 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.502322 46.9062C7.17759 35.8315 14.6246 26.2079 25.8688 19.4009C36.7746 12.7881 49.6186 8.89839 62.3551 8.05335C84.8311 6.57966 108.896 15.5464 124.197 32.2998C123.759 30.7282 123.331 29.1743 122.868 27.6409C121.079 21.6595 120.342 12.3167 115.746 7.7569C111.911 3.9558 117.877 -1.94165 121.705 1.88743C126.484 6.66139 127.252 13.8085 128.75 20.0869C130.6 27.8088 133.18 36.0112 133.815 43.9922C134.445 44.5659 135.024 45.2159 135.486 45.9692C136.009 46.829 135.451 47.729 134.624 48.0619C129.664 50.0912 123.838 46.5211 119.111 44.6998C113.373 42.4878 107.381 40.2676 102.172 36.9828C99.155 35.0799 101.071 29.4466 104.72 30.9041C110.387 33.157 115.72 36.1454 121.332 38.5484C105.183 24.5066 85.6048 15.3925 63.4746 16.4191C41.1149 17.4592 16.2273 30.2955 2.96154 48.3597C1.89769 49.7988 -0.486509 48.5152 0.484515 46.9164L0.502322 46.9062Z'
      fill={ color }/>
  </svg>
)

const RightArrow = ({ color }) => (
  <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 5.69995H15.4997V7.69963H0V5.69995Z' fill={ color }/>
    <path d='M11.2004 13.4006L9.7998 12L15.1004 6.7003L9.7998 1.40062L11.2004 0L17.8998 6.70032L11.2004 13.4006Z' fill={ color }/>
  </svg>
)

const Instagram = ({ color }) => (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M5.24027 0H12.9166C15.7992 0 18.1565 2.32812 18.1565 5.17224V12.8278C18.1565 15.6726 15.7992 18 12.9166 18H5.24027C2.35762 18 0 15.6726 0 12.8278V5.17224C0 2.32812 2.35759 0 5.24027 0ZM9.02938 4.13988C11.8306 4.13988 14.1038 6.41305 14.1038 9.21428C14.1038 12.0159 11.8306 14.2887 9.02938 14.2887C6.22747 14.2887 3.95464 12.0159 3.95464 9.21428C3.95464 6.41305 6.22747 4.13988 9.02938 4.13988ZM9.0293 5.854C10.8843 5.854 12.3898 7.35917 12.3898 9.21448C12.3898 11.0698 10.8843 12.5753 9.0293 12.5753C7.17366 12.5753 5.66848 11.0698 5.66848 9.21448C5.66848 7.35917 7.17366 5.854 9.0293 5.854ZM13.9386 3.43699C14.3936 3.43699 14.7625 3.8059 14.7625 4.26061C14.7625 4.71566 14.3936 5.08457 13.9386 5.08457C13.4839 5.08457 13.1149 4.71566 13.1149 4.26061C13.1149 3.8059 13.4839 3.43699 13.9386 3.43699ZM5.86749 1.47014H12.2899C14.7015 1.47014 16.6737 3.43268 16.6737 5.83087V12.2854C16.6737 14.6836 14.7015 16.6458 12.2899 16.6458H5.86749C3.45594 16.6458 1.48335 14.6836 1.48335 12.2854V5.83082C1.48335 3.43268 3.45594 1.47014 5.86749 1.47014Z'
          fill={ color }/>
  </svg>
)

const Facebook = ({ color }) => (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M18.1562 0H0.15625V18H18.1562V0ZM9.77177 17.9999H12.8047L12.8042 10.799H14.828L15.0963 8.31781H12.8042L12.8084 7.07585C12.8084 6.42874 12.8709 6.08201 13.8175 6.08201H15.0824V3.59991H13.0586C10.6271 3.59991 9.77177 4.80415 9.77177 6.82819V8.31781H8.25625V10.799H9.77177V17.9999Z'
          fill={ color }/>
  </svg>

)

const Loading = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <circle cx="50" cy="50" fill="none" stroke='#000' strokeWidth="10" r="25" strokeDasharray="117.80972450961724 41.269908169872416"></circle>
  </svg>
)

const Linkedin = ({ color }) => (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M18.1562 0H0.15625V18H18.1562V0ZM4.73265 5.94153H4.71632C3.89991 5.94153 3.3706 5.34066 3.3706 4.57976C3.3706 3.8033 3.91556 3.21429 4.7483 3.21429C5.58036 3.21429 6.09198 3.80182 6.10831 4.57754C6.10831 5.33844 5.58036 5.94153 4.73265 5.94153ZM3.66203 6.92429H5.91737V14.7845H3.66203V6.92429ZM12.3844 14.7858H14.9418V10.4145C14.9418 7.9807 13.6825 6.84936 12.0796 6.84936C10.7876 6.84936 9.97799 7.61915 9.82831 8.15852V6.92493H7.29471C7.32737 7.57988 7.29471 14.7858 7.29471 14.7858H9.82831V10.5323L9.82802 10.4474C9.82695 10.2397 9.82592 10.0387 9.88342 9.88999C10.0576 9.41656 10.4318 8.92683 11.1046 8.92683C11.9843 8.92683 12.3844 9.6529 12.3844 10.7176V14.7858Z'
          fill={ color }/>
  </svg>
)

const UploadSVG = ({ color }) => (
  <svg width='32' height='40' viewBox='0 0 32 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.1084 0.0266113H5.26642C2.37424 0.0266113 0.0234375 2.37975 0.0234375 5.26959V34.7284C0.0234375 37.6206 2.37658 39.9714 5.26642 39.9714H26.7374C29.6273 39.9714 31.9804 37.6182 31.9804 34.7284V11.0614L22.1084 0.0266113ZM22.7975 4.54533L27.935 10.2875H22.7975V4.54533ZM26.7373 37.4751H5.2663C3.75226 37.4751 2.52178 36.2447 2.52178 34.7306L2.51943 5.26941C2.51943 3.75537 3.75225 2.52489 5.26395 2.52489H20.2994V12.7861H29.48V34.7281C29.4823 36.2445 28.249 37.4751 26.7373 37.4751Z'
      fill={ color }/>
    <path d='M9.84375 21.9337L11.7422 23.5579L14.6461 20.1618V30.378H17.1445V20.1618L20.0461 23.5579L21.9446 21.9337L15.8953 14.8579L9.84375 21.9337Z' fill={ color }/>
  </svg>
)

const Close = ({ color }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M9.653 8.48144C9.84513 8.67357 10.1577 8.67204 10.3483 8.48144L17.8506 0.979128C18.0427 0.786997 18.3507 0.783948 18.5429 0.976078L19.0247 1.45794C19.2153 1.64854 19.2123 1.95963 19.0217 2.1502L11.5194 9.65251C11.3272 9.84464 11.3288 10.1572 11.5194 10.3478L19.0217 17.8501C19.2138 18.0423 19.2169 18.3503 19.0247 18.5424L18.5429 19.0242C18.3523 19.2148 18.0412 19.2118 17.8506 19.0212L10.3483 11.5189C10.1562 11.3268 9.84357 11.3283 9.653 11.5189L2.15069 19.0212C1.95856 19.2133 1.65056 19.2164 1.45843 19.0242L0.976566 18.5424C0.785962 18.3518 0.78901 18.0407 0.979616 17.8501L8.48193 10.3478C8.67406 10.1557 8.67253 9.84308 8.48193 9.65251L0.979616 2.1502C0.787485 1.95807 0.784437 1.65007 0.976566 1.45794L1.45843 0.976078C1.64903 0.785474 1.96011 0.788522 2.15069 0.979128L9.653 8.48144Z'
          fill={ color }/>
  </svg>
)

const ArrowDown = ({ color }) => (
  <svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M13.1281 1.55078C13.2906 1.38749 13.2906 1.12421 13.1281 0.960935L12.5382 0.371875C12.3757 0.209375 12.1117 0.209375 11.9492 0.371875L6.99998 5.32188L2.05077 0.371875C1.88749 0.209375 1.62421 0.209375 1.46093 0.371875L0.871875 0.960935C0.709375 1.12422 0.709375 1.3875 0.871875 1.55078L6.70548 7.38358C6.86798 7.54686 7.13204 7.54686 7.29454 7.38358L13.1281 1.55078Z'
          fill={ color }/>
  </svg>
)

const ArrowLeft = ({ color }) => (
  <svg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15.5 5.1665H2.5836V6.8329H15.5V5.1665Z' fill='#0F2130'/>
    <path d='M6.1668 11.5837L7.33398 10.4165L2.91678 6.00008L7.33398 1.58368L6.1668 0.416504L0.584004 6.0001L6.1668 11.5837Z' fill={ color }/>
  </svg>
)

const ArrowUp = ({ color }) => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg' className='svg-arrow-up'>
    <path fillRule='evenodd' clipRule='evenodd'
          d='M0.710313 4.54368C0.596562 4.65798 0.596562 4.84227 0.710313 4.95657L1.1232 5.36891C1.23695 5.48266 1.42179 5.48266 1.53554 5.36891L4.99998 1.90391L8.46442 5.36891C8.57872 5.48266 8.76302 5.48266 8.87731 5.36891L9.28965 4.95657C9.40341 4.84227 9.40341 4.65798 9.28965 4.54368L5.20613 0.460723C5.09238 0.346426 4.90754 0.346426 4.79379 0.460723L0.710313 4.54368Z'
          fill='black'/>
  </svg>
)

const RoundedOpen = ({ color }) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='14' cy='14' r='13.5' stroke={ color }/>
    <path d='M13.2 6.80005V13.2H6.79999V14.8H13.2V21.2H14.8V14.8H21.2V13.2H14.8V6.80005H13.2Z' fill={ color }/>
  </svg>
)

const RoundedDownload = ({ color }) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='14' cy='14' r='13.5' stroke='#3F5B73'/>
    <path d='M18.4164 11.3336L14.8336 14.9164V6.5H13.1664V14.9164L9.58362 11.3336L8.41644 12.5L14 18.0836L19.5836 12.5L18.4164 11.3336Z' fill='#0F2130'/>
    <path d='M9.41644 19.8335H18.5828V21.4999H9.41644V19.8335Z' fill={ color }/>
  </svg>
)

const RoundedPlay = ({ color }) => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='14' cy='14' r='13.5' stroke='#3F5B73'/>
    <path d='M21 14L10 20L10 8L21 14Z' fill={ color }/>
  </svg>
)

const Options = ({ color }) => (
  <svg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.07953 3.99989H14.8553C15.1068 5.28834 16.2553 6.23989 17.5995 6.23989C18.9437 6.23989 20.091 5.28834 20.3437 3.99989H22.9195C23.2279 3.99989 23.4795 3.74833 23.4795 3.43989C23.4795 3.13146 23.2279 2.87989 22.9195 2.87989H20.3437C20.0921 1.59144 18.9437 0.639893 17.5995 0.639893C16.2553 0.639893 15.1079 1.59144 14.8553 2.87989H1.07953C0.771083 2.87989 0.519531 3.13146 0.519531 3.43989C0.519531 3.74833 0.771094 3.99989 1.07953 3.99989ZM17.5995 1.75989C18.5238 1.75989 19.2795 2.51567 19.2795 3.43989C19.2795 4.36412 18.5238 5.11989 17.5995 5.11989C16.6753 5.11989 15.9195 4.36412 15.9195 3.43989C15.9195 2.51567 16.6753 1.75989 17.5995 1.75989ZM22.9195 10.4399H9.14381C8.89225 9.15145 7.74381 8.19989 6.39959 8.19989C5.05536 8.19989 3.90804 9.15145 3.65536 10.4399H1.07959C0.771139 10.4399 0.519587 10.6915 0.519587 10.9999C0.519587 11.3083 0.771151 11.5599 1.07959 11.5599H3.65536C3.90693 12.8483 5.05536 13.7999 6.39959 13.7999C7.74381 13.7999 8.89114 12.8483 9.14381 11.5599H22.9195C23.228 11.5599 23.4795 11.3083 23.4795 10.9999C23.4795 10.6915 23.228 10.4399 22.9195 10.4399ZM6.39953 12.6799C5.47531 12.6799 4.71953 11.9241 4.71953 10.9999C4.71953 10.0757 5.47531 9.31989 6.39953 9.31989C7.32376 9.31989 8.07953 10.0757 8.07953 10.9999C8.07953 11.9241 7.32376 12.6799 6.39953 12.6799ZM22.9195 17.9999H14.7438C14.4922 16.7114 13.3438 15.7599 11.9996 15.7599C10.6554 15.7599 9.50804 16.7114 9.25536 17.9999H1.07964C0.771195 17.9999 0.519643 18.2515 0.519643 18.5599C0.519643 18.8683 0.771206 19.1199 1.07964 19.1199H9.25536C9.50693 20.4083 10.6554 21.3599 11.9996 21.3599C13.3438 21.3599 14.4911 20.4083 14.7438 19.1199H22.9195C23.228 19.1199 23.4795 18.8683 23.4795 18.5599C23.4795 18.2515 23.228 17.9999 22.9195 17.9999ZM11.9995 20.2399C11.0753 20.2399 10.3195 19.4841 10.3195 18.5599C10.3195 17.6357 11.0753 16.8799 11.9995 16.8799C12.9238 16.8799 13.6795 17.6357 13.6795 18.5599C13.6795 19.4841 12.9238 20.2399 11.9995 20.2399Z'
      fill={ color }/>
  </svg>
)

const Plus = ({ color }) => (
  <svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.44 0.459961V4.93996H0.959999V6.05996H5.44V10.54H6.56V6.05996H11.04V4.93996H6.56V0.459961H5.44Z' fill={ color }/>
  </svg>
)

const FilledArrowDown = ({ color }) => (
  <svg width='13' height='6' viewBox='0 0 13 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.5 6L0.870835 -1.06037e-06L12.1292 -7.61308e-08L6.5 6Z' fill={ color }/>
  </svg>
)

const FilledArrowRight = ({ color }) => (
  <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.5 6L0.5 11.6292L0.5 0.370835L6.5 6Z' fill={ color }/>
  </svg>
)

const FilledArrowUp = ({ color }) => (
  <svg width='13' height='6' viewBox='0 0 13 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.5 1.1365e-06L12.1292 6L0.870834 6L6.5 1.1365e-06Z' fill={ color }/>
  </svg>
)

const ShortArrowRight = ({ color }) => (
  <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.600586 5.99963H12.3006V7.99931H0.600586V5.99963Z' fill='#0F2130'/>
    <path d='M8.30101 13.7003L6.90039 12.2997L12.201 6.99998L6.90039 1.7003L8.30101 0.299683L15.0004 7L8.30101 13.7003Z' fill={ color }/>
  </svg>
)

const ShortLogo = ({ color }) => (
  <svg width='40' height='22' viewBox='0 0 40 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.98815 21.448C12.9361 21.448 16.0441 18.872 16.7442 15.064H12.6281C11.9281 16.688 10.6401 17.584 8.98815 17.584C6.80415 17.584 5.09615 15.764 5.09615 13.44C5.09615 11.088 6.77615 9.296 8.98815 9.296C10.6121 9.296 11.9841 10.192 12.6281 11.676H16.6881C15.9601 7.952 12.9361 5.46 9.01615 5.46C4.42415 5.46 0.952148 8.876 0.952148 13.412C0.952148 17.948 4.42415 21.448 8.98815 21.448Z'
      fill='black'/>
    <path
      d='M27.3121 5.544C25.4921 5.544 23.8961 6.216 22.7481 7.308V0.447998H18.6601V21H22.7481V13.076C22.7481 10.612 24.0921 9.128 26.1362 9.128C27.9562 9.128 28.7682 10.248 28.7682 12.516V21H32.8561V11.816C32.8561 7.98 30.7281 5.544 27.3121 5.544Z'
      fill='black'/>
    <path d='M35.4897 21H39.8297V16.632H35.4897V21Z' fill={ color }/>
  </svg>
)

const QtyPlus = () => (
  <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='14' cy='14' r='13.5' stroke='#D6D690'/>
    <path d='M13.1998 6.80005V13.2H6.7998V14.8H13.1998V21.2H14.7998V14.8H21.1998V13.2H14.7998V6.80005H13.1998Z' fill='#0F2130'/>
  </svg>
)

const QtyMinus = () => (
  <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='14.333' cy='14' r='13.5' stroke='#D6D690'/>
    <path d='M7.13281 13.2H15.1328H21.5328V14.8H15.1328H7.13281V13.2Z' fill='#0F2130'/>
  </svg>
)

const Download = ({ color }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.4165 7.3336L10.8337 10.9164V2.5H9.16648V10.9164L5.58368 7.3336L4.4165 8.5L10.0001 14.0836L15.5837 8.5L14.4165 7.3336Z' fill={ color }/>
    <path d='M5.4165 15.8335H14.5829V17.4999H5.4165V15.8335Z' fill={ color }/>
  </svg>
)

export {
  Logo,
  StackedLogo,
  DropdownArrow,
  DownCurledArrow,
  DownCurledLongArrow,
  RightArrow,
  LeftCurledArrow,
  Instagram,
  Facebook,
  Linkedin,
  UploadSVG,
  Close,
  ArrowDown,
  ArrowLeft,
  ArrowUp,
  Options,
  RoundedOpen,
  RoundedPlay,
  RoundedDownload,
  Plus,
  FilledArrowDown,
  FilledArrowUp,
  FilledArrowRight,
  ShortLogo,
  ShortArrowRight,
  QtyPlus,
  QtyMinus,
  RightCurledArrow,
  Download,
  Loading
}
