import React, { Component } from 'react'
import Link from '../utils/link'
import { StackedLogo, Instagram, Linkedin, Facebook } from './icons'

class Footer extends Component {
  render() {
    const offices = this.props.offices
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__left'>
              <div className='footer__logo'>
                <Link to='/'><StackedLogo color='#0F2130'/></Link>
                <ul>
                  <li><Link><Instagram color='#0F2130'/></Link></li>
                  <li><Link><Linkedin color='#0F2130'/></Link></li>
                  <li><Link><Facebook color='#0F2130'/></Link></li>
                </ul>
              </div>

              <ul>
                <h3>Contact Us</h3>
                { offices?.map((office, i) => (
                  <li key={i}>
                    <Link to={office.uri}>{ office.title }</Link>
                  </li>
                )) }
              </ul>
            </div>

            <div className='footer__right'>
              <div className='footer__column'>
                <ul>
                  <h3>Buy</h3>
                  <li><Link to='/for-sale/'>For Sale</Link></li>
                  <li><Link to='/opentimes-sales/'>Upcoming inspections</Link></li>
                  <li><Link to='/buy-upcoming-auctions/'>Upcoming auctions</Link></li>
                  <li><Link to='/commercial-for-sale/'>Commercial for sale</Link></li>
                  <li><Link to='/our-neighbourhoods/'>Our neighbourhoods</Link></li>
                </ul>
              </div>
              <div className='footer__column'>
                <ul>
                  <h3>Sell</h3>
                  <li><Link to='/sell-with-us/'>Sell with us</Link></li>
                  <li><Link to='/sold/'>Recent sales</Link></li>
                  <li><Link to='/commercial-sold/'>Commercial sales</Link></li>
                  <li><Link to='/appraisals/'>Request appraisal</Link></li>
                </ul>
              </div>
              <div className='footer__column'>
                <ul>
                  <h3>Rent</h3>
                  <li><Link to='/for-lease/'>Rent with us</Link></li>
                  <li><Link to='/opentimes-lease/'>Upcoming inspections</Link></li>
                  <li><Link to='/emergency-trades/'>Urgent repairs</Link></li>
                  <li><Link to='/commercial-lease/'>Commercial for lease</Link></li>
                </ul>
              </div>
              <div className='footer__column'>
                <ul>
                  <h3>Invest</h3>
                  <li><Link to='/management/'>Manage with us</Link></li>
                  <li><Link to='/appraisals/'>Request appraisal</Link></li>
                </ul>
              </div>
              <div className='footer__column'>
                <ul>
                  <h3>About</h3>
                  <li><Link to='/our-team/'>Our team</Link></li>
                  <li><Link to='/about-us/'>About us</Link></li>
                  <li><Link to='/blog/'>Blog</Link></li>
                  <li><Link to='/careers/'>Careers</Link></li>
                  <li><Link to='/contact/'>Contact</Link></li>
                </ul>
              </div>

              <div className='footer__column footer__socials'>
                <ul>
                  <li><Link to='https://www.instagram.com/cobden.hayson/'><Instagram color='#0F2130'/></Link></li>
                  <li><Link to='https://www.linkedin.com/company/cobdenhayson/'><Linkedin color='#0F2130'/></Link></li>
                  <li><Link to='https://www.facebook.com/cobdenandhayson'><Facebook color='#0F2130'/></Link></li>
                </ul>
              </div>
            </div>
          </div>

          <div className='footer__bottom'>
            <div className='footer__copyright'>
              <p>© Cobden & Hayson Holdings Pty Ltd<br />ABN:  12 601 355 980<br />
              All rights reserved.</p>
              <Link to='https://atollon.com.au/'>Site by Atollon</Link>
            </div>
            

            <ul>
              <li><Link to='/terms-conditions/'>Terms & Conditions</Link></li>
              <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              <li><Link to='https://atollon.com.au'>Site by Atollon</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
